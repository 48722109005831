<template>
  <div class="page-wrapper">
    <div class="filter">
      <div class="filter-item border-right-splite">
        <span class="label">
          <pre>日期范围：</pre>
        </span>
        <span class="input">
          <DatePicker type="daterange"
                      size="small"
                      style="width: 230px"
                      :start-date="filterOptions.startDate"
                      :value="filterOptions.dateRange"
                      format="yyyy-MM-dd"
                      :editable="false"
                      placement="bottom-start"
                      @on-change="dateChangeHandler"></DatePicker>
        </span>
      </div>
      <div class="filter-item border-right-splite">
        <!-- <Button
          class="btn"
          v-for="(period, index) in periodList"
          :key="period.text"
          :type="period.type"
          @click.native="periodHandler(index)"
          >{{ period.text }}</Button
        > -->
        <ButtonGroup>
          <Button class="btn"
                  :type="
              filterOptions.checkdate == 'lastweek' ? 'primary' : 'default'
            "
                  @click="periodHandler('lastweek'), fetchData()">上周</Button>
          <Button class="btn"
                  :type="filterOptions.checkdate == 'theweek' ? 'primary' : 'default'"
                  @click="periodHandler('theweek'), fetchData()">本周</Button>
        </ButtonGroup>
        <ButtonGroup style="margin-right: 0.2rem; margin-left: 0.2rem">
          <Button :type="
              filterOptions.checkdate == 'themonth' ? 'primary' : 'default'
            "
                  icon="ios-skip-backward"
                  :disabled="theMouth <= 1 ? true : false"
                  @click="periodHandler('themonth'), theMouth--, changeMouth()"></Button>
          <Button class="btn"
                  :type="
              filterOptions.checkdate == 'themonth' ? 'primary' : 'default'
            "
                  @click="
              periodHandler('themonth'),
                (theMouth = new Date().getMonth() + 1),
                fetchData()
            ">本月</Button>
          <Button :type="
              filterOptions.checkdate == 'themonth' ? 'primary' : 'default'
            "
                  :disabled="theMouth >= 12 ? true : false"
                  icon="ios-skip-forward"
                  @click="periodHandler('themonth'), theMouth++, changeMouth()"></Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button :type="filterOptions.checkdate == 'theYear' ? 'primary' : 'default'"
                  icon="ios-skip-backward"
                  @click="periodHandler('theYear'), theYear--, changeYear()"></Button>
          <Button class="btn"
                  :type="filterOptions.checkdate == 'theYear' ? 'primary' : 'default'"
                  @click="
              periodHandler('theYear'),
                (theYear = new Date().getFullYear()),
                changeYear()
            ">本年</Button>
          <Button :type="filterOptions.checkdate == 'theYear' ? 'primary' : 'default'"
                  icon="ios-skip-forward"
                  @click="periodHandler('theYear'), theYear++, changeYear()"></Button>
        </ButtonGroup>
      </div>
    </div>

    <div class="content">
      <div class="left">
        <div class="statistics white-block">
          <h3 class="statistics-title border-bottom-black">飞行统计</h3>
          <div class="statistics-item border-right-black">
            <div class="sta-title">飞行次数</div>
            <div class="sta-data">{{ pageData.countres.count }}次</div>
          </div>
          <div class="statistics-item border-right-black">
            <div class="sta-title">飞行时间</div>
            <div class="sta-data">
              {{ formatSeconds(pageData.countres.alltime) }}
            </div>
          </div>
          <div class="statistics-item">
            <div class="sta-title">飞行距离</div>
            <div class="sta-data">
              {{ formatMiles(pageData.countres.distance) }}
            </div>
          </div>
        </div>

        <div class="time-rank rank white-block">
          <div class="rank-title border-bottom-black">
            <!-- {{ isSuperAdmin ? "总飞行时长" : "飞手飞行时长" }} -->
            飞手飞行时长
          </div>
          <div style="height: 10px"></div>
          <div class="rank-item"
               v-for="(rank, index) in pageData.flyorder"
               :key="index">
            <!-- <img class="img"
                  :src="medals[index]"
                  alt
                  v-if="index < 3" /> -->
            <span class="img">{{ index + 1 }}</span>
            <span class="center">{{ rank.name }}</span>
            <span>{{ formatSeconds(rank.flytime) }}</span>
          </div>
          <div class="no-data"
               v-show="pageData.flyorder.length === 0">
            <img :src="noData"
                 alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="chart white-block">
          <div class="title">
            <!-- <span>每日飞行数据</span> -->
            <!-- <span class="desc">(蓝线每日数据，紫线为平均值)</span> -->
            <span>每日飞行里程(米)</span>
          </div>
          <div class="lines"
               id="chart"></div>
        </div>
        <div class="r-bottom">
          <div class="equ-rank rank white-block">
            <div class="rank-title border-bottom-black">飞行架次</div>
            <!-- <div class="rank-item border-bottom-splite"
                 v-for="(rank, index) in pageData.flycount"
                 :key="index">
              <span class="center">{{ rank.DRONEMODEL }}</span>
              <span>{{ formatCount(rank.count) }}</span>
            </div> -->
            <div v-show="pageData.flycount.length"
                 class="fly-count">
              <div id="pie"></div>
              <div class="count">
                <div v-for="(n, idx) in pageData.flycount.slice(0, 5)"
                     :key="idx">
                  <span></span>
                  {{ n.DRONEMODEL }}
                  <div style="float: right">{{ n.count }}次</div>
                </div>
              </div>
            </div>
            <div class="no-data"
                 v-show="pageData.flycount.length === 0">
              <img :src="noData"
                   alt="" />
              <p>暂无数据</p>
            </div>
          </div>

          <div class="equ-rank rank white-block">
            <div class="rank-title border-bottom-black">里程排行</div>
            <div style="height: 10px"></div>
            <div class="rank-item distance"
                 v-for="(rank, index) in pageData.distanceres"
                 :key="index">
              <span>{{ index + 1 }}</span><span class="drone">{{ rank.DRONEMODEL }}</span>
              <div class="slider">
                <div :style="{
                    width:
                      (
                        (rank.distance / pageData.distanceres[0].distance) *
                        100
                      ).toFixed(1) + '%',
                  }"></div>
              </div>
              <span class="num">{{ formatMiles(rank.distance) }}</span>
            </div>
            <div class="no-data"
                 v-show="pageData.distanceres.length === 0">
              <img :src="noData"
                   alt="" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <Spin size="large"
            fix
            v-if="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
import GoldMedal from '@/assets/img/statistics/gold-medal.png';
import SilverMedal from '@/assets/img/statistics/silver-medal.png';
import BronzeMedal from '@/assets/img/statistics/bronze-medal.png';
import noData from '@/assets/img/noData.png';

import _ from 'lodash';
import Api from '@/utils/api.js';
export default {
  name: 'statistics',

  data() {
    return {
      filterUpdateLock: true, // 页面初始化的时候需要锁住不让相关变量被赋值的时候产生更新导致页面重新请求数据 等页面加载完成之后就可以了
      spinShow: true,
      filterOptions: {
        startDate: this.getLastMonthDate(),
        dateRange: ['', ''],
        // second_id: -1,
        // pm_first_id: -1,
        team: -1,
        checkdate: 'theweek',
      },
      filterConfigs: {
        team: [],
        project: [],
        company: [],
      },
      pageData: {
        countres: {
          count: 0,
          alltime: 0,
          distance: 0,
        },
        dis_every: [],
        flyorder: [],
        distanceres: [],
        flycount: [],
      },
      periodList: [
        {
          text: '本周',
          type: 'primary',
          value: 'theweek',
        },
        {
          text: '上周',
          type: 'default',
          value: 'lastweek',
        },
        {
          text: '本月',
          type: 'default',
          value: 'themonth',
        },
        {
          text: '上月',
          type: 'default',
          value: 'lastmonth',
        },
      ],
      medals: [GoldMedal, SilverMedal, BronzeMedal],
      noData,
      theMouth: 0,
      theYear: 0,
    };
  },

  computed: {
    // 超管
    // isSuperAdmin() {
    //   return (
    //     JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
    //     0
    //   );
    // },
    // first_id: {
    //   get() {
    //     return { first_id: 36 };
    //   },
    // },
    // 获取数据的参数
    fetchParams: {
      get() {
        return {
          startdate: this.filterOptions.dateRange[0] || '',
          enddate:
            this.filterOptions.dateRange[1] ||
            this.$moment().format('YYYY-MM-DD'),
          checkdate: this.filterOptions.checkdate,
          team_id:
            this.user_info.permission == 0
              ? this.user_info.team_id
              : this.filterOptions.team,
        };
      },
    },
  },

  // watch: {
  //   // 修改参数之后重新获取
  //   filterOptions: {
  //     deep: true,
  //     handler: function () {
  //       if (this.filterUpdateLock) return;
  //       this.fetchData();
  //     },
  //   },
  // },

  methods: {
    getLastMonthDate() {
      this.theMouth = new Date().getMonth() + 1;

      return new Date(new Date().getTime() - 259200000); // 当前日期的前一个月
    },
    // 日期改变之后重新获取数据
    dateChangeHandler(e) {
      if (e[1] == '') {
        this.filterOptions.checkdate = 'theweek';
      } else {
        this.filterOptions.checkdate = '';
      }
      this.filterOptions.dateRange = e;
      this.fetchData();
    },

    // 格式化日期
    formatSeconds(seconds) {
      let hour = parseInt(seconds / 3600);
      let minute = parseInt((seconds - 3600 * hour) / 60);
      seconds = seconds - 3600 * hour - 60 * minute;
      return (
        (hour ? hour + '时' : '') +
        (minute ? minute + '分' : '') +
        seconds +
        '秒'
      );
    },
    // 格式化里程
    formatMiles(miles) {
      let km = parseFloat(miles / 1000).toFixed(1);
      return km >= 1 ? km + '千米 ' : miles + '米';
    },

    // chart数据
    initChart() {
      this.myChart_1 = this.$echarts.init(document.getElementById('chart'));
      const option_1 = {
        grid: {
          top: 10,
          bottom: 40,
          left: '10%',
          right: '5%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
          },
          splitLine: {
            show: false,
            interval: 0,
            lineStyle: {
              color: this.$store.state.darkTheme ? '#F8F8F8' : '#888',
            },
          },
          axisLabel: {
            show: true,
            margin: 18,
            // interval: 0,
            // rotate: -20,
            fontSize: 10,
            color: this.$store.state.darkTheme ? '#F8F8F8' : '#888',
          },
          data: [],
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: this.$store.state.darkTheme ? '#F8F8F8' : '#888',
            },
          },
          axisLabel: {
            show: true,
            margin: 18,
            // interval: 0,
            // rotate: -20,
            fontSize: 10,
            color: this.$store.state.darkTheme ? '#F8F8F8' : '#888',
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            // smooth: true,
            // showSymbol: false,
            clip: true,
            lineStyle: {
              color: '#B2E7FF',
              // width: 4,
              // smooth: true,
              // smoothMonotone: 'x',
            },
            itemStyle: {
              color: '#B2E7FF',
              borderColor: '#67C7F2',
              borderWidth: 1,
            },
            // markLine: {
            //   silent: true,
            //   label: {
            //     show: false,
            //   },
            //   lineStyle: {
            //     color: '#C179E5',
            //     width: 3,
            //     type: 'solid',
            //   },
            //   data: [
            //     [
            //       {
            //         symbol: 'none',
            //         x: '95%',
            //         yAxis: 'average',
            //       },
            //       {
            //         symbol: 'none',
            //         type: 'average',
            //         name: '平均值',
            //         x: '10%',
            //       },
            //     ],
            //   ],
            // },
            data: [],
          },
        ],
      };
      option_1.xAxis.data = this.pageData.dis_every.map((ele) => {
        return ele.date || '';
      });
      option_1.series[0].data = this.pageData.dis_every.map((ele) => {
        return parseInt(ele.distance) || 0;
      });
      // option.xAxis.data = ['2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03','2020-02-02', '2020-02-03']
      // option.series[0].data = [100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50,100, 50]
      this.myChart_1.setOption(option_1);
      let _this = this;
      this.resize = _.debounce(function () {
        _this.myChart_1.resize();
      }, 1000);
      ///// 圆饼
      this.myChart_2 = this.$echarts.init(document.getElementById('pie'));
      const option_2 = {
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: '80%',
            data: [],
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      option_2.series[0].data = this.pageData.flycount
        .slice(0, 5)
        .map((ele) => {
          return { value: ele.count, name: ele.DRONEMODEL };
        });
      this.myChart_2.setOption(option_2);

      window.addEventListener('resize', this.resize);
    },
    // 获取配置并做映射 添加全部选项
    fetchConfig() {
      this.$post(Api.getRrackAllCountConfig())
        .then((res) => {
          if (res.data) {
            console.log('工程数据', res.data);
            // if (!this.isSuperAdmin) {
            this.filterConfigs.project =
              (res.data.secondpm &&
                res.data.secondpm.map((item) => {
                  return {
                    label: item.pm_name,
                    value: item.pm_id,
                  };
                })) ||
              [];
            if (this.filterConfigs.project.length) {
              this.filterConfigs.project.unshift({
                label: '全部',
                value: -1,
              });
            }
            // } else {
            //   this.filterConfigs.company =
            //     (res.data.firstpm &&
            //       res.data.firstpm.map((item) => {
            //         return {
            //           label: item.pm_name,
            //           value: item.pm_id,
            //         };
            //       })) ||
            //     [];
            //   if (this.filterConfigs.company.length) {
            //     this.filterConfigs.company.unshift({
            //       label: '全部',
            //       value: -1,
            //     });
            //   }
            // }
            this.filterConfigs.team =
              (res.data.teamlist &&
                res.data.teamlist.map((item) => {
                  return {
                    label: item.team_name,
                    value: item.id,
                  };
                })) ||
              [];
            if (this.filterConfigs.team.length) {
              this.filterConfigs.team.unshift({
                label: '全部',
                value: -1,
              });
            }
            this.fetchData();
          } else {
            this.$notice.error({
              title: '配置接口异常,返回无数据',
            });
          }
          this.filterUpdateLock = false;
        })
        .catch((err) => {
          this.spinShow = false;
          this.filterUpdateLock = false;
          this.$notice.error({
            title: '配置接口异常,返回无数据',
            desc: err.toString(),
          });
        });
    },
    // 获取数据
    fetchData() {
      this.$post(Api.getRrackAllCount(), _.merge({}, this.fetchParams))
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.pageData = res.data;
          } else {
            this.$notice.error({
              title: '统计列表接口异常,返回无数据',
            });
          }
          this.initChart();
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '统计列表接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.filterUpdateLock = false;
          this.spinShow = false;
        });
    },
    // 切换日期种类
    periodHandler(value) {
      this.filterOptions.checkdate = value;
      // this.periodList = this.periodList.map((ele, i) => {
      //   return {
      //     text: ele.text,
      //     type: parseInt(index) === i ? "primary" : "default",
      //     value: ele.value,
      //   };
      // });
      this.filterOptions.dateRange = ['', ''];
    },
    // 月份切换
    changeMouth() {
      let year = new Date().getFullYear();
      if (
        this.theMouth == 1 ||
        this.theMouth == 3 ||
        this.theMouth == 5 ||
        this.theMouth == 7 ||
        this.theMouth == 8 ||
        this.theMouth == 10 ||
        this.theMouth == 12
      ) {
        this.filterOptions.dateRange = [
          year + '-' + this.theMouth + '-01',
          year + '-' + this.theMouth + '-31',
        ];
      } else if (this.theMouth == 2) {
        this.filterOptions.dateRange = [
          year + '-' + this.theMouth + '-01',
          year + '-' + this.theMouth + '-28',
        ];
      } else {
        this.filterOptions.dateRange = [
          year + '-' + this.theMouth + '-01',
          year + '-' + this.theMouth + '-30',
        ];
      }
      this.fetchData();
    },
    // 年份切换
    changeYear() {
      console.log(this.theYear);
      this.filterOptions.dateRange = [
        this.theYear + '-01-01',
        this.theYear + '-12-31',
      ];
      this.fetchData();
    },
  },

  mounted() {
    this.user_info = JSON.parse(
      window.sessionStorage.getItem('user_info') || '{}'
    );
    if (sessionStorage.getItem('team_id') === null) {
      this.filterOptions.team = this.user_info.team_id;
      this.fetchData();
    } else {
      this.filterOptions.team = Number(sessionStorage.getItem('team_id'));
      this.fetchData();
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.filterOptions.team = value;
      this.fetchData();
    });
    this.theYear = new Date().getFullYear();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  height: 100%;
  background-color: $xf_hei2;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  /*font-size: pcRem(18);*/
  .filter {
    display: flex;
    align-items: center;
    padding-top: 0.1rem;
    flex-wrap: wrap;
    margin-bottom: 0.1rem;
    flex-wrap: nowrap;
    .filter-item {
      display: flex;
      align-items: center;
      padding: 0 0.45rem;
      margin-bottom: 0.1rem;
      line-height: 1;
      &:last-child {
        border-right: none;
      }
      .label {
        white-space: pre;
        font-size: 0.16rem;
        color: $font_color_1;
      }
      .btn {
        width: 1.12rem;
        &:last-child {
          margin-right: 0;
        }
      }
      .ivu-select-item {
        padding: 0.07rem 0.16rem;
      }
    }
  }
  /deep/ .ivu-input-wrapper {
    width: 230px !important;
  }
  .content {
    position: relative;
    flex: 1;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    .left {
      flex: 0 0 30%;
      margin-right: 0.1rem;
      display: flex;
      flex-direction: column;
      .statistics {
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;

        padding: 0.1rem 0 0.3rem;
        flex-wrap: wrap;
        color: $font_color_1;
        .statistics-title {
          width: 100%;
          padding-left: 0.19rem;
          padding-bottom: 0.08rem;
          margin-bottom: 0.26rem;
          font-size: 0.18rem;
        }
        .statistics-item {
          flex: 1;
          text-align: center;
          white-space: nowrap;
          .sta-title {
            padding-top: 0.2rem;
            font-size: 0.14rem;
            font-weight: normal;
          }
          .sta-data {
            padding: 0.2rem 0.3rem;
            font-size: 0.2rem;
            font-weight: normal;
            color: $font_color_1;
          }
        }
      }
      .time-rank {
        font-weight: bold;
        flex: 1;
        color: #333;
      }
    }
    .rank {
      white-space: nowrap;
      // padding: 0 0.3rem;
      overflow-y: auto;
      color: #333;
      .rank-title {
        // font-size: 0.24rem;
        // color: #333;
        // font-weight: bold;
        // padding: 0.3rem 0;

        padding: 0.1rem 0.2rem;
        font-size: 0.18rem;
        font-weight: normal;
        color: $font_color_1;
      }

      .rank-item {
        display: flex;
        align-items: center;
        margin: 0 10px;
        padding-right: 0.2rem;
        margin-bottom: 0.02rem;
        background-color: $xf_hui7;
        font-weight: 400;
        color: $font_color_1;
        .img {
          width: 0.43rem;
          height: 0.54rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .center {
          flex: 1;
          padding-left: 0.4rem;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      .rank-item:first-child {
        margin-top: 10px;
      }
      .distance {
        width: 6rem;
        height: 0.48rem;
        margin: 0.02rem auto;
        justify-content: space-between;
        padding-left: 0.2rem;
        .drone {
          width: 1.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .slider {
          width: 3.4rem;
          height: 0.13rem;
          background-color: $white;
          div {
            background-color: $blue;
            height: 100%;
          }
        }
        .num {
          width: 50px;
          text-align: right;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      .chart {
        padding: 0 0.2rem 0.2rem;
        margin-bottom: 0.1rem;
        .title {
          padding: 0.1rem 0;
          font-size: 0.18rem;
          color: $font_color_1;
          .desc {
            font-size: 0.18rem;
            color: #999;
            font-weight: normal;
            margin-left: 0.4rem;
          }
        }
        .lines {
          height: 4rem;
        }
      }
      .r-bottom {
        overflow: hidden;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: nowrap;
        .equ-rank {
          flex: 1;
          &:first-child {
            margin-right: 0.1rem;
          }
          .rank-title {
            padding: 0.1rem 0 0.1rem 0.2rem;
          }
          .fly-count {
            display: flex;
            #pie {
              width: 3.5rem;
              height: 2.5rem;
            }
            .count {
              color: $white;
              font-size: 0.14rem;

              > div {
                width: 2rem;
                margin-top: 0.3rem;
                span {
                  vertical-align: text-top;
                  display: inline-block;
                  width: 0.17rem;
                  height: 0.17rem;
                  border-radius: 50%;
                  background: #d53a35;
                }
              }
              > div:nth-child(2) {
                span {
                  background: #334b5c;
                }
              }
              > div:nth-child(3) {
                span {
                  background: #61a0a8;
                }
              }
              > div:nth-child(4) {
                span {
                  background: #d48265;
                }
              }
              > div:nth-child(5) {
                span {
                  background: #9fdabf;
                }
              }
            }
          }
        }
      }
    }
  }
}
.white-block {
  background-color: $xf_hui5;
}
</style>
